import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';
import useLoginData from '@src/Components/Login/useLoginData';
import { some } from 'lodash';

import { RouteChildrenProps, Link } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { FormContainer, FormAlertContainer } from '../../atoms/containers';
import { GhostBtn, PrimaryButton } from '../../atoms/buttons';
import { FormLogo } from '../../atoms/images';
import { VerticalGap } from '../../atoms/spacing';
import { H2 } from '../../atoms/typography';
import LoginErrorAlert from '@ts/components/molecules/LoginErrorAlert';
import LoggedOutIdleAlert from '@ts/components/molecules/LoggedOutIdleAlert';

export const LoginPage = ({ history }: RouteChildrenProps) => {
  const {
    formData: { email, password },
    errors,
    success,
    loginError,
    isSignInClicked,
    loggedOutFromIdle,
    handleChange,
    handleDidClickSignIn,
    handleKeyUp,
    handleDismissError,
    handleDismissIdle
  } = useLoginData(history);

  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {},
    mainLogo
  } = useTheme();

  const alert = useMemo(() => {
    if (loginError || window.location.search.includes('error')) {
      return (
        <LoginErrorAlert
          message={config?.loginFailureMessage}
          handleDismiss={handleDismissError}
        />
      );
    }

    if (loggedOutFromIdle) {
      return (
        <LoggedOutIdleAlert
          title={config?.loggedOutIdleTitle}
          message={config?.loggedOutIdleMessage}
          handleDismiss={handleDismissIdle}
        />
      );
    }

    return null;
  }, [
    loginError,
    loggedOutFromIdle,
    config?.loggedOutIdleMessage,
    config?.loggedOutIdleTitle,
    config?.loginFailureMessage,
    handleDismissError,
    handleDismissIdle
  ]);

  const validated = useMemo(() => {
    if (!email || !password) return false;
    if (some(errors, Boolean)) return false;
    return true;
  }, [email, password, errors]);

  return (
    <UnauthenticatedFormContainer>
      <FormAlertContainer>{alert}</FormAlertContainer>
      <FormLogo alt={mainLogo.alt} src={mainLogo.src} />
      <FormContainer>
        <H2 $align="center">{config?.loginTitle ?? 'Log in'}</H2>
        <FormInput
          label={config?.loginEmailField?.label ?? 'Email*'}
          inputType="text"
          inputID="email"
          data-testid="email-input"
          placeholder={
            config?.loginEmailField?.placeholder ?? 'you@example.com'
          }
          value={email}
          required={true}
          onChange={handleChange}
          invalidFeedback={
            config?.loginEmailField?.errorMessage ?? 'Please enter your email'
          }
          errors={errors}
          success={success}
        />
        <VerticalGap $gap={24} />

        <FormInput
          label={config?.loginPasswordField?.label ?? 'Password*'}
          showLabel={config?.loginPasswordField?.showLabel}
          hideLabel={config?.loginPasswordField?.hideLabel}
          inputType="password"
          inputID="password"
          data-testid="password-input"
          placeholder={config?.loginPasswordField?.placeholder ?? ''}
          value={password}
          required={true}
          onChange={handleChange}
          invalidFeedback={
            config?.loginPasswordField?.errorMessage ??
            'Please enter a password that is at least 8 characters long with at least one letter and one number'
          }
          errors={errors}
          success={success}
          onKeyUp={handleKeyUp}
        />
      </FormContainer>
      <VerticalGap $gap={40} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ padding: 10 }}></div>
        <PrimaryButton
          data-testid="loginform-submit-btn"
          onClick={handleDidClickSignIn}
          type="button"
          disabled={!validated}
          $minWidth="160px"
        >
          {isSignInClicked ? (
            <i className="fas fa-circle-notch fa-spin" />
          ) : (
            config?.signInButton ?? 'Sign in'
          )}
        </PrimaryButton>
      </div>

      <Link
        to={{
          pathname: '/forgot-password',
          state: {
            email: email
          }
        }}
      >
        <GhostBtn>{config?.forgotPasswordText ?? 'Forgot Password?'}</GhostBtn>
      </Link>
    </UnauthenticatedFormContainer>
  );
};
