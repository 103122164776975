import React, {
  useCallback,
  useState,
  forwardRef,
  ForwardedRef,
  InputHTMLAttributes
} from 'react';
import { omit } from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { LabelContainer, ToggleVisibilityBtn } from '../atoms/form';
import { useTranslation } from 'react-i18next';

type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  inputID: string;
  invalidFeedback?: React.JSX.Element | string;
  label?: any;
  description?: string | Document;
  errors?: { [inputId: string]: boolean };
  inputType?: string;
  showLabel?: string;
  maxLength?: number;
  hideLabel?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  descriptionClassName?: string;
  success?: any;
  visible?: boolean;
  'data-testid'?: string;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
};

export function FormInput(props: Readonly<FormInputProps>) {
  const [hidePassword, setHidePassword] = useState(true);

  const { t } = useTranslation();

  const togglePasswordVisibility = useCallback(() => {
    setHidePassword(h => !h);
  }, []);

  const {
    inputID,
    label,
    description,
    inputType,
    errors,
    invalidFeedback,
    wrapperClassName,
    labelClassName,
    descriptionClassName,
    showLabel,
    hideLabel
  } = props;

  return (
    <>
      <div className={wrapperClassName} data-testid={`form-input-${inputID}`}>
        {inputType !== 'checkbox' ? (
          <LabelContainer>
            <label className={labelClassName} htmlFor={inputID}>
              {label}
            </label>
            {inputType === 'password' ? (
              <ToggleVisibilityBtn
                type="button"
                className="btn btn-link"
                onClick={togglePasswordVisibility}
                data-testid={`toggle-visibility-${inputID}`}
              >
                {hidePassword
                  ? showLabel ?? t('authFormInput.showPassword')
                  : hideLabel ?? t('authFormInput.hidePassword')}
              </ToggleVisibilityBtn>
            ) : null}
          </LabelContainer>
        ) : null}
        {inputType !== 'checkbox' ? (
          <LabelDescription
            description={description}
            className={descriptionClassName}
          />
        ) : null}
        <Input {...props} hidePassword={hidePassword} />
        {inputType === 'checkbox' ? (
          <label className={labelClassName} htmlFor={inputID}>
            {label}
            {description ? (
              <LabelDescription
                description={description}
                className={descriptionClassName}
              />
            ) : null}
          </label>
        ) : null}
      </div>
      {errors?.[inputID] ? (
        <div
          data-testid={`invalid-feedback-${inputID}`}
          className="invalid-feedback"
        >
          {invalidFeedback}
        </div>
      ) : null}
    </>
  );
}

export default forwardRef<HTMLInputElement, FormInputProps>(
  function FormInputWithRef(props, ref) {
    return <FormInput {...props} forwardedRef={ref} />;
  }
);

function Input(props: Readonly<FormInputProps & { hidePassword?: boolean }>) {
  const {
    inputType,
    inputID,
    errors,
    success,
    inputWrapperClassName,
    maxLength,
    forwardedRef,
    hidePassword,
    ...rest
  } = props;
  let className = inputType === 'checkbox' ? 'form-check' : 'form-control';
  if (errors?.[inputID] && props.value) {
    className = `${className} is-invalid`;
  }
  if (success?.[inputID]) {
    className = `${className} is-valid`;
  }

  const type = inputType === 'password' && !hidePassword ? 'text' : inputType;
  return (
    <div className={inputWrapperClassName ?? 'input-group'}>
      <input
        ref={forwardedRef}
        maxLength={maxLength}
        autoComplete="on"
        className={className}
        type={type}
        id={inputID}
        data-testid={props['data-testid']}
        {...omit(rest, [
          'invalidFeedback',
          'wrapperClassName',
          'labelClassName',
          'showLabel',
          'hideLabel',
          'label',
          'description'
        ])}
      />
    </div>
  );
}

function LabelDescription({
  className,
  description
}: Readonly<{
  className?: string;
  description?: string | Document;
}>) {
  if (!description) return null;
  return (
    <small className={`d-block ${className ?? ''}`}>
      {typeof description === 'string'
        ? description
        : documentToReactComponents(description)}
    </small>
  );
}
