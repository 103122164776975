import { Professional } from '@ts/models';
import { ColumnConfig, ProfessionalContentConfiguration } from 'theme';
import i18n from '../../i18n';

const columnConfigs: Array<ColumnConfig<Professional>> = [
  {
    id: 'name',
    label: i18n.t('professionalContent.columnConfigs.name'),
    sortable: true
  },
  {
    id: 'invitationStatus',
    label: i18n.t('professionalContent.columnConfigs.status'),
    sortable: true
  },
  {
    id: 'sites',
    label: i18n.t('professionalContent.columnConfigs.siteAccess'),
    sortable: true
  },
  {
    id: 'roleName',
    label: i18n.t('professionalContent.columnConfigs.role'),
    sortable: true
  }
];

const professionalContentConfiguration: ProfessionalContentConfiguration = {
  columnConfigs,
  professionalSeatsUsedLabel: i18n.t(
    'professionalContent.professionalSeatsUsedLabel'
  ),
  searchByNameInputPlaceholder: i18n.t(
    'professionalContent.searchByNameInputPlaceholder'
  ),
  displayingProfessionalsLabel: i18n.t(
    'professionalContent.displayingProfessionalsLabel'
  ),
  professionalsLabel: i18n.t('professionalContent.professionalsLabel'),
  emptyListMessage: i18n.t('professionalContent.emptyListMessage'),
  createProfessionalModalTitle: i18n.t(
    'professionalContent.createProfessionalModalTitle'
  ),
  createProfessionalModalSubmitButtonText: i18n.t(
    'professionalContent.createProfessionalModalSubmitButtonText'
  ),
  userAlreadyExistsError: i18n.t('professionalContent.userAlreadyExistsError'),
  statusLabelAccepted: i18n.t('professionalContent.statusLabelAccepted'),
  statusLabelPending: i18n.t('professionalContent.statusLabelPending'),
  statusLabelLocked: i18n.t('professionalContent.statusLabelLocked'),
  statusLabelDisabled: i18n.t('professionalContent.statusLabelDisabled'),
  professionalDetailNameLabel: i18n.t(
    'professionalContent.professionalDetailNameLabel'
  ),
  professionalDetailEmailLabel: i18n.t(
    'professionalContent.professionalDetailEmailLabel'
  ),
  professionalDetailPhoneLabel: i18n.t(
    'professionalContent.professionalDetailPhoneLabel'
  ),
  professionalDetailRoleLabel: i18n.t(
    'professionalContent.professionalDetailRoleLabel'
  ),
  professionalDetailSitesLabel: i18n.t(
    'professionalContent.professionalDetailSitesLabel'
  ),
  professionalDetailBlindingLabel: i18n.t(
    'professionalContent.professionalDetailBlindingLabel'
  ),
  professionalDetailBlindingsLabel: i18n.t(
    'professionalContent.professionalDetailBlindingsLabel'
  ),
  editProfessionalButtonLabel: i18n.t(
    'professionalContent.editProfessionalButtonLabel'
  ),
  editPersonalButtonLabel: i18n.t(
    'professionalContent.editPersonalButtonLabel'
  ),
  personalInfoTitle: i18n.t('professionalContent.personalInfoTitle'),
  professionalInfoTitle: i18n.t('professionalContent.professionalInfoTitle'),
  professionalEditReasonDisclaimer: i18n.t(
    'professionalContent.professionalEditReasonDisclaimer'
  ),
  editProfessionalInfoModalTitle: i18n.t(
    'professionalContent.editProfessionalInfoModalTitle'
  ),
  editProfessionalInfoModalSubtitle: i18n.t(
    'professionalContent.editProfessionalInfoModalSubtitle'
  ),
  editProfessionalInfoModalSubmitButtonText: i18n.t(
    'professionalContent.editProfessionalInfoModalSubmitButtonText'
  ),
  editProfessionalInfoModalCancelButtonText: i18n.t(
    'professionalContent.editProfessionalInfoModalCancelButtonText'
  ),
  editPersonalInfoModalTitle: i18n.t(
    'professionalContent.editPersonalInfoModalTitle'
  ),
  editPersonalInfoModalSubmitButtonText: i18n.t(
    'professionalContent.editPersonalInfoModalSubmitButtonText'
  ),
  editPersonalInfoModalCancelButtonText: i18n.t(
    'professionalContent.editPersonalInfoModalCancelButtonText'
  ),
  reasonForChangeModalTitle: i18n.t(
    'professionalContent.reasonForChangeModalTitle'
  ),
  reasonForChangeModalSubtitle: i18n.t(
    'professionalContent.reasonForChangeModalSubtitle'
  ),
  reasonForChangeModalSubmitButtonText: i18n.t(
    'professionalContent.reasonForChangeModalSubmitButtonText'
  ),
  reasonForChangeModalCancelButtonText: i18n.t(
    'professionalContent.reasonForChangeModalCancelButtonText'
  ),
  professionalDetailsError: i18n.t(
    'professionalContent.professionalDetailsError'
  ),
  createProfessionalModalFieldsConfig: {
    firstName: {
      id: 'firstName',
      placeholder: i18n.t('professionalContent.firstName.placeholder'),
      label: i18n.t('professionalContent.firstName.label'),
      required: true
    },
    lastName: {
      id: 'lastName',
      placeholder: i18n.t('professionalContent.lastName.placeholder'),
      label: i18n.t('professionalContent.lastName.label'),
      required: true
    },
    email: {
      id: 'email',
      placeholder: i18n.t('professionalContent.email.placeholder'),
      label: i18n.t('professionalContent.email.label'),
      required: true
    },
    role: {
      id: 'role',
      placeholder: i18n.t('professionalContent.role.placeholder'),
      label: i18n.t('professionalContent.role.label'),
      required: true
    },
    sites: {
      id: 'sites',
      placeholder: i18n.t('professionalContent.sites.placeholder'),
      label: i18n.t('professionalContent.sites.label'),
      required: true,
      selectAllLabel: i18n.t('professionalContent.sites.selectAllLabel')
    },
    blindingPermission: {
      id: 'blindingPermission',
      placeholder: i18n.t('professionalContent.blindingPermission.placeholder'),
      label: i18n.t('professionalContent.blindingPermission.label'),
      required: true,
      selectAllLabel: i18n.t(
        'professionalContent.blindingPermission.selectAllLabel'
      )
    }
  },
  editProfessionalModalFieldsConfig: {
    firstName: {
      id: 'firstName',
      placeholder: i18n.t('professionalContent.firstName.placeholder'),
      label: i18n.t('professionalContent.firstName.label'),
      required: true
    },
    lastName: {
      id: 'lastName',
      placeholder: i18n.t('professionalContent.lastName.placeholder'),
      label: i18n.t('professionalContent.lastName.label'),
      required: true
    },
    email: {
      id: 'email',
      placeholder: i18n.t('professionalContent.email.placeholder'),
      label: i18n.t('professionalContent.email.label'),
      required: true
    },
    role: {
      id: 'role',
      placeholder: i18n.t('professionalContent.role.placeholder'),
      label: i18n.t('professionalContent.role.label'),
      required: true
    },
    sites: {
      id: 'sites',
      placeholder: i18n.t('professionalContent.sites.placeholder'),
      label: i18n.t('professionalContent.sites.label'),
      required: true,
      selectAllLabel: i18n.t('professionalContent.sites.selectAllLabel')
    },
    blindingPermission: {
      id: 'blindingPermission',
      placeholder: i18n.t('professionalContent.blindingPermission.placeholder'),
      label: i18n.t('professionalContent.blindingPermission.label'),
      required: true,
      selectAllLabel: i18n.t(
        'professionalContent.blindingPermission.selectAllLabel'
      )
    }
  },
  reasonForChangeModalHeaderTitle: i18n.t(
    'professionalContent.reasonForChangeModalHeaderTitle'
  ),
  reasonForChangeModalBodyText: i18n.t(
    'professionalContent.reasonForChangeModalBodyText'
  ),
  reasonForChangeInputLabel: i18n.t(
    'professionalContent.reasonForChangeInputLabel'
  ),
  reasonForChangeInputPlaceholder: i18n.t(
    'professionalContent.reasonForChangeInputPlaceholder'
  ),
  reasonForChangeErrorMessage: i18n.t(
    'professionalContent.reasonForChangeErrorMessage'
  ),
  reasonForChangeModalCancelButtonLabel: i18n.t(
    'professionalContent.reasonForChangeModalCancelButtonLabel'
  ),
  reasonForChangeModalSubmitButtonLabel: i18n.t(
    'professionalContent.reasonForChangeModalSubmitButtonLabel'
  ),
  resendInviteBtnLabel: i18n.t('professionalContent.resendInviteBtnLabel'),
  resendInviteModalHeaderTitle: i18n.t(
    'professionalContent.resendInviteModalHeaderTitle'
  ),
  resendInviteModalMessage: i18n.t(
    'professionalContent.resendInviteModalMessage'
  ),
  resendInviteSuccessMessage: i18n.t(
    'professionalContent.resendInviteSuccessMessage'
  ),
  resendInviteModalCancelButtonLabel: i18n.t(
    'professionalContent.resendInviteModalCancelButtonLabel'
  ),
  resendInviteModalSubmitButtonLabel: i18n.t(
    'professionalContent.resendInviteModalSubmitButtonLabel'
  ),
  resendPasswordLinkBtnLabel: i18n.t(
    'professionalContent.resendPasswordLinkBtnLabel'
  ),
  resendPasswordLinkModalHeaderTitle: i18n.t(
    'professionalContent.resendPasswordLinkModalHeaderTitle'
  ),
  resendPasswordLinkSuccessMessage: i18n.t(
    'professionalContent.resendPasswordLinkSuccessMessage'
  ),
  resendPasswordLinkModalCancelButtonLabel: i18n.t(
    'professionalContent.resendPasswordLinkModalCancelButtonLabel'
  ),
  resendPasswordLinkModalSubmitButtonLabel: i18n.t(
    'professionalContent.resendPasswordLinkModalSubmitButtonLabel'
  ),
  resetPasswordBtnLabel: i18n.t('professionalContent.resetPasswordBtnLabel'),
  resetPasswordModalHeaderTitle: i18n.t(
    'professionalContent.resetPasswordModalHeaderTitle'
  ),
  resetPasswordInputLabel: i18n.t(
    'professionalContent.resetPasswordInputLabel'
  ),
  resetPasswordErrorMessage: i18n.t(
    'professionalContent.resetPasswordErrorMessage'
  ),
  resetPasswordModalCancelButtonLabel: i18n.t(
    'professionalContent.resetPasswordModalCancelButtonLabel'
  ),
  resetPasswordModalSubmitButtonLabel: i18n.t(
    'professionalContent.resetPasswordModalSubmitButtonLabel'
  ),
  resetPasswordSuccessMessage: i18n.t(
    'professionalContent.resetPasswordSuccessMessage'
  ),
  resetPasswordReasonForChangeModalBodyText: i18n.t(
    'professionalContent.resetPasswordReasonForChangeModalBodyText'
  ),
  resetPasswordReasonForChangeModalCancelText: i18n.t(
    'professionalContent.resetPasswordReasonForChangeModalCancelText'
  ),
  resetPasswordReasonForChangeModalSubmitText: i18n.t(
    'professionalContent.resetPasswordReasonForChangeModalSubmitText'
  ),
  deactivateProfessionalButtonLabel: i18n.t(
    'professionalContent.deactivateProfessionalButtonLabel'
  ),
  deactivateProfessionalModalTitle: i18n.t(
    'professionalContent.deactivateProfessionalModalTitle'
  ),
  deactivateProfessionalModalDescription: i18n.t(
    'professionalContent.deactivateProfessionalModalDescription'
  ),
  deactivateProfessionalModalSubmitBtnLabel: i18n.t(
    'professionalContent.deactivateProfessionalModalSubmitBtnLabel'
  ),
  deactivateProfessionalModalCancelBtnLabel: i18n.t(
    'professionalContent.deactivateProfessionalModalCancelBtnLabel'
  ),
  activateProfessionalButtonLabel: i18n.t(
    'professionalContent.activateProfessionalButtonLabel'
  ),
  activateProfessionalModalTitle: i18n.t(
    'professionalContent.activateProfessionalModalTitle'
  ),
  activateProfessionalModalDescription: i18n.t(
    'professionalContent.activateProfessionalModalDescription'
  ),
  activateProfessionalModalSubmitBtnLabel: i18n.t(
    'professionalContent.activateProfessionalModalSubmitBtnLabel'
  ),
  activateProfessionalModalCancelBtnLabel: i18n.t(
    'professionalContent.activateProfessionalModalCancelBtnLabel'
  ),
  deleteProfessionalButtonLabel: i18n.t(
    'professionalContent.deleteProfessionalButtonLabel'
  ),
  deleteProfessionalModalTitle: i18n.t(
    'professionalContent.deleteProfessionalModalTitle'
  ),
  deleteProfessionalModalDescription: i18n.t(
    'professionalContent.deleteProfessionalModalDescription'
  ),
  deleteProfessionalModalSubmitBtnLabel: i18n.t(
    'professionalContent.deleteProfessionalModalSubmitBtnLabel'
  ),
  deleteProfessionalModalCancelBtnLabel: i18n.t(
    'professionalContent.deleteProfessionalModalCancelBtnLabel'
  ),
  deleteProfessionalModalSuccessMessage: i18n.t(
    'professionalContent.deleteProfessionalModalSuccessMessage'
  ),
  unlockProfessionalLabel: i18n.t(
    'professionalContent.unlockProfessionalLabel'
  ),
  unlockProfessionalModalTitle: i18n.t(
    'professionalContent.unlockProfessionalModalTitle'
  ),
  unlockProfessionalReasonInputLabel: i18n.t(
    'professionalContent.unlockProfessionalReasonInputLabel'
  ),
  unlockProfessionalReasonInputPlaceholder: i18n.t(
    'professionalContent.unlockProfessionalReasonInputPlaceholder'
  ),
  unlockProfessionalSubmitLabel: i18n.t(
    'professionalContent.unlockProfessionalSubmitLabel'
  ),
  unlockProfessionalCancelLabel: i18n.t(
    'professionalContent.unlockProfessionalCancelLabel'
  )
};
export default professionalContentConfiguration;
