import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';

import { RouteChildrenProps } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { FormContainer } from '../atoms/containers';
import { PrimaryButton } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import { PasswordStrength } from '../molecules/PasswordStrength';
import { useResetPasswordSubmissionMutation } from '@src/ts/services/api';
import usePasswordRules from '@ts/hooks/usePasswordRules';
import useOnEnterKeyUp from '@ts/hooks/useOnEnterKeyUp';
import { SessionManagementFeedback } from '../organisms/SessionManagementFeedback';
import { H2, H4 } from '../atoms/typography';
import MessageModal from '../molecules/MessageModal';
import { SessionManagementAlert } from '../organisms/SessionManagementAlert';
import { useAuth } from '@src/selectors/auth';
import { useDispatch } from 'react-redux';
import { logout as reducerLogout } from '@src/actions/auth';
import { logout } from '@src/utils/OneVueAPI';

export const ResetPasswordPage = ({
  history,
  match
}: RouteChildrenProps<{ token: string }>) => {
  const [passwordReset, setPasswordReset] = useState('');
  const [errorModal, setErrorModal] = useState<string | null>(null);
  const [tokenError, setTokenError] = useState<boolean>(false);

  const { authToken } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(reducerLogout(authToken));
      logout(true);
    }
  }, [authToken, dispatch]);

  const [
    submitResetPassword,
    { error, isLoading: loading, isSuccess: success, reset: clear }
  ] = useResetPasswordSubmissionMutation();

  useEffect(() => {
    if (error) {
      let errStr: string = (error as any).data.error;
      if (errStr?.toLowerCase().includes('token')) {
        setTokenError(true);
      } else {
        setErrorModal(errStr);
      }
    }
  }, [error]);

  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config,
      localPasswordMgtContentConfiguration
    } = {},
    mainLogo
  } = useTheme();

  const handleDidClickSavePassword = useCallback(() => {
    submitResetPassword({
      newPassword: passwordReset,
      confirmPassword: passwordReset,
      resetPasswordToken: `${match?.params?.token}`
    });
  }, [passwordReset, match?.params?.token, submitResetPassword]);

  const handleGoToPortal = useCallback(() => {
    history?.replace('/login');
    clear();
  }, [clear, history]);

  const handleSendResetLink = useCallback(() => {
    history?.replace('/forgot-password');
    clear();
  }, [clear, history]);

  const handleChangePasswordReset = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPasswordReset(event.target.value);
    },
    []
  );

  const { rules, validated } = usePasswordRules(passwordReset);

  const { handleKeyUp } = useOnEnterKeyUp(
    handleDidClickSavePassword,
    validated
  );

  if (tokenError) {
    return (
      <UnauthenticatedFormContainer>
        <VerticalGap $gap={70} />
        <FormLogo alt={mainLogo.alt} src={mainLogo.src} />
        <VerticalGap $gap={80} />
        <SessionManagementAlert
          title={
            config?.resetPasswordFailureAlertTitle || 'Your link has expired'
          }
          message={
            config?.resetPasswordFailureMessage ||
            'The password reset request for your account has expired.'
          }
          display
        />
        <PrimaryButton
          data-testid="password-token-error-proceed-btn"
          onClick={handleSendResetLink}
          type="button"
          $minWidth="160px"
        >
          {config?.resetPasswordFailureButtonLabel || 'Send new reset link'}
        </PrimaryButton>
      </UnauthenticatedFormContainer>
    );
  }

  return (
    <UnauthenticatedFormContainer>
      <VerticalGap $gap={70} />
      {!success && <FormLogo alt={mainLogo.alt} src={mainLogo.src} />}
      <FormContainer>
        {success ? (
          <SessionManagementFeedback
            image={config?.resetPasswordImage}
            fallbackIconSrc="/portal/lockCheck.svg"
            message={
              config?.resetPasswordSuccessMessage ||
              'You successfully changed your password!\nYou are now signed in.'
            }
          />
        ) : (
          <>
            <H2 $align="center" $textTransform="none">
              {config?.resetPasswordTitle || 'Reset password'}
            </H2>

            <H4 $fontWeight={400} $align="center" $margin="8px 0 16px 0">
              {config?.resetPasswordSubtitle ||
                `Save a new password to access your account.`}
            </H4>
            <VerticalGap $gap={24} />
            <FormInput
              maxLength={64}
              label={config?.resetPasswordPasswordField?.label || 'Password'}
              showLabel={config?.resetPasswordPasswordField?.showLabel}
              hideLabel={config?.resetPasswordPasswordField?.hideLabel}
              inputType="password"
              inputID="password"
              data-testid="password-input"
              value={passwordReset}
              placeholder={
                config?.resetPasswordPasswordField?.placeholder || ''
              }
              required={true}
              onChange={handleChangePasswordReset}
              onKeyUp={handleKeyUp}
            />
            {passwordReset && (
              <>
                <VerticalGap $gap={16} />
                <PasswordStrength password={passwordReset} rules={rules} />
              </>
            )}
          </>
        )}
      </FormContainer>
      <MessageModal
        title={
          localPasswordMgtContentConfiguration?.resetPasswordFailureModalTitle ||
          'Password reset failed'
        }
        visible={!!errorModal}
        submitBtnLabel={
          localPasswordMgtContentConfiguration?.resetPasswordFailureModalSubmitBtnLabel
        }
        message={errorModal || 'Something went wrong. Please try again later.'}
        onClose={() => setErrorModal(null)}
      />
      <VerticalGap $gap={40} />
      {success ? (
        <PrimaryButton
          data-testid="password-saved-proceed-btn"
          onClick={handleGoToPortal}
          type="button"
          $minWidth="160px"
        >
          {config?.resetPasswordSuccessButtonLabel || 'Go to Portal'}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          $minWidth="160px"
          data-testid="reset-password-submit-btn"
          onClick={handleDidClickSavePassword}
          type="button"
          disabled={!validated}
        >
          {loading ? (
            <i
              className="fas fa-circle-notch fa-spin"
              data-testid="loading-spinner"
            />
          ) : (
            config?.resetPasswordButtonLabel || 'Save password'
          )}
        </PrimaryButton>
      )}
    </UnauthenticatedFormContainer>
  );
};
