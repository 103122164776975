import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { BasicResponse } from 'ipep-api';
import config from '@src/config/config.json';
import {
  AuditQueryParams,
  GetAuditsResponse,
  ProfessionalQueryParams,
  GetProfessionalsResponse,
  AvailableSitesResponse,
  StudiesResponse,
  AssignedStudiesResponse,
  StudiesArgs,
  StudyCreateMutationArgs,
  StudyUpdateMutationArgs,
  StudyDeletionArgs,
  GetUserResponse,
  GetParticipantProfileResponse,
  GetUser,
  RolesResponse,
  CreateProfessionalMutationArgs,
  GetProfessionalDetailsResponse,
  UpdatePassword,
  GetParticipantsResponse,
  ParticipantQueryParams,
  UpdateProfessionalMutationArgs,
  GetAuditsActionsResponse,
  GetAuditsSubjectsResponse,
  GetAuditsSitesResponse,
  GetAuditsUsersResponse,
  CreateParticipantMutationArgs,
  ResendProfessionalInviteMutationArgs,
  UserActionMutationArgs,
  ResetUserPasswordMutationArgs,
  GetParticipantProfile,
  GetSummaryResponse,
  GetSummaryArgs,
  CreateTagMutationArgs,
  AssignTagMutationArgs,
  GetParticipantDevicesResponse,
  GetParticipantDevicesQueryParams,
  GetTagsResponse,
  GetTagsQueryParams,
  SearchTagsQueryParams,
  GetFlagsQueryParams,
  GetFlagsResponse,
  DeleteParticipant,
  GetParticipantSurveysResponse,
  GetParticipantSurveysQueryParams,
  SurveyQuestionsResponse,
  GetActivitiesResponse,
  GetActivitiesQueryParams,
  GetDevicesListResponse,
  GetParticipantDevicesListParms,
  GetDeviceHistoryResponse,
  GetDeviceHistoryParams,
  GetActivityOptionsResponse,
  GetActivityOptionsQueryParams,
  deleteProfessional
} from '../models';
import { RootState } from './store';
import moment from 'moment';

const { BASE_URL } = config;

export function prepareHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) {
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  headers.set('Selected-Language', moment.locale());
  // temporary until RootState is created
  const authToken = (api.getState() as RootState)?.auth?.authToken;
  if (authToken) headers.set('Session-Key', authToken);
}

function handleQueryParam(param?: string | string[]) {
  if (param?.length === 0) return undefined;

  if (Array.isArray(param)) {
    return param.join(',');
  }

  return param;
}

function transformErrorResponse(response: FetchBaseQueryError) {
  return {
    status: response.status,
    ...(response.data as BasicResponse)
  };
}

function transformNullValuesToEmptyString<T extends object>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = '' as any;
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      transformNullValuesToEmptyString(obj[key] as object);
    }
  }
  return obj;
}

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders
  }),
  endpoints: builder => ({
    /* Sites */
    getAvailableSites: builder.query<AvailableSitesResponse, void>({
      query: () => 'availableSites'
    }),
    /* Studies */
    getAssignedStudies: builder.query<AssignedStudiesResponse, void>({
      query: () => 'studies/assigned'
    }),
    getStudies: builder.query<StudiesResponse, StudiesArgs>({
      query: ({ order } = {}) => {
        const queryParams = new URLSearchParams(order ?? {});
        return order ? `studies?${queryParams}` : 'studies';
      }
    }),
    createStudy: builder.mutation<BasicResponse, StudyCreateMutationArgs>({
      query: ({ body }) => ({
        url: 'studies',
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    updateStudy: builder.mutation<BasicResponse, StudyUpdateMutationArgs>({
      query: ({ id, body }) => ({
        url: `studies/${id}`,
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    deleteStudy: builder.mutation<BasicResponse, StudyDeletionArgs>({
      query: ({ id, reason }) => ({
        url: `studies/${id}`,
        method: 'DELETE',
        body: { reason }
      }),
      transformErrorResponse
    }),
    /* Roles */
    getRoles: builder.query<RolesResponse, void>({
      query: () => 'roles'
    }),
    // Surveys
    getSurveyAnswers: builder.query<
      SurveyQuestionsResponse,
      { surveyId: string }
    >({
      query: ({ surveyId }) => `surveys/${surveyId}`
    }),
    // Forms
    getForms: builder.query<any, { participantId: string }>({
      query: ({ participantId }) => `forms/${participantId}`
    }),
    getParticipantForms: builder.query<any, { participantId: string }>({
      query: ({ participantId }) => `forms/${participantId}/applied`
    }),
    createParticipantForm: builder.mutation<any, any>({
      query: ({ participantId, formId, body }) => ({
        url: `forms/${participantId}/${formId}`,
        method: 'POST',
        body
      })
    }),
    // Graphs
    getBGMSummary: builder.query<GetSummaryResponse, GetSummaryArgs>({
      query: ({
        trialId,
        userId,
        startDate,
        endDate,
        minThreshold,
        maxThreshold,
        group,
        includeRecords,
        includeSummary,
        sortBy,
        sortOrder,
        skip,
        limit,
        unit
      }) => ({
        url: `participants/graphs/bgm/${trialId}/${userId}`,
        method: 'GET',
        params: {
          startDate,
          endDate,
          minThreshold,
          maxThreshold,
          group,
          includeRecords,
          includeSummary,
          sortBy,
          sortOrder,
          skip,
          limit,
          unit
        }
      }),
      transformErrorResponse
    }),
    getCGMSummary: builder.query<GetSummaryResponse, GetSummaryArgs>({
      query: ({
        trialId,
        userId,
        startDate,
        endDate,
        minThreshold,
        maxThreshold,
        group,
        includeRecords,
        includeSummary,
        sortBy,
        sortOrder,
        skip,
        limit,
        unit
      }) => ({
        url: `participants/graphs/cgm/${trialId}/${userId}`,
        method: 'GET',
        params: {
          startDate,
          endDate,
          minThreshold,
          maxThreshold,
          group,
          includeRecords,
          includeSummary,
          sortBy,
          sortOrder,
          skip,
          limit,
          unit
        }
      }),
      transformErrorResponse
    }),
    /* Audits */
    getAudits: builder.query<GetAuditsResponse, AuditQueryParams>({
      query: ({
        startDate,
        endDate,
        users,
        sites,
        subjects,
        actions,
        sortBy,
        sortOrder,
        skip,
        limit
      }) => ({
        url: 'audits',
        method: 'GET',
        params: {
          startDate,
          endDate,
          users: handleQueryParam(users),
          sites: handleQueryParam(sites),
          subjects: handleQueryParam(subjects),
          actions: handleQueryParam(actions),
          sortBy,
          sortOrder,
          skip,
          limit
        }
      })
    }),
    getAuditsActions: builder.query<GetAuditsActionsResponse, {}>({
      query: () => 'audits/actions'
    }),
    getAuditsSubjects: builder.query<GetAuditsSubjectsResponse, {}>({
      query: () => 'audits/subjects'
    }),
    getAuditsSites: builder.query<GetAuditsSitesResponse, {}>({
      query: () => 'audits/sites'
    }),
    getAuditsUsers: builder.query<GetAuditsUsersResponse, {}>({
      query: () => 'audits/users'
    }),
    /* Professionals */
    getProfessionals: builder.query<
      GetProfessionalsResponse,
      ProfessionalQueryParams
    >({
      query: ({
        name,
        invitationStatuses,
        sites,
        roles,
        blindings,
        trialId,
        sortBy,
        sortOrder,
        skip,
        limit
      }) => ({
        url: `professionals/${trialId}`,
        method: 'GET',
        params: {
          name: handleQueryParam(name),
          invitationStatuses: handleQueryParam(invitationStatuses),
          sites: handleQueryParam(sites),
          roles: handleQueryParam(roles),
          blindings: handleQueryParam(blindings),
          sortBy,
          sortOrder,
          skip,
          limit
        }
      })
    }),
    getProfessionalById: builder.query<
      GetProfessionalDetailsResponse,
      { id: string }
    >({
      query: ({ id }) => `professionals/details/${id}`
    }),
    createProfessional: builder.mutation<
      BasicResponse,
      CreateProfessionalMutationArgs
    >({
      query: body => ({
        url: 'professionals',
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    resendProfessionalInvite: builder.mutation<
      BasicResponse,
      ResendProfessionalInviteMutationArgs
    >({
      query: ({ email, id }) => ({
        url: `professionals/resend/${id}`,
        method: 'POST',
        body: { email }
      }),
      transformErrorResponse
    }),
    updateProfessional: builder.mutation<
      BasicResponse,
      Partial<UpdateProfessionalMutationArgs> & { id: string; reason: string }
    >({
      query: ({ id, ...body }) => ({
        url: `professionals/${id}`,
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    /* User */
    createPasswordSubmission: builder.mutation({
      query: formData => ({
        url: 'createPassword',
        method: 'POST',
        body: formData
      })
    }),
    resetPasswordSubmission: builder.mutation({
      query: ({ newPassword, confirmPassword, resetPasswordToken }) => ({
        url: 'password',
        method: 'POST',
        body: {
          newPassword,
          confirmPassword,
          resetPasswordToken
        }
      })
    }),
    forgotPasswordSubmission: builder.mutation({
      query: ({ email }) => ({
        url: 'password',
        method: 'POST',
        body: { email }
      })
    }),
    getUser: builder.query<GetUserResponse, void>({
      query: () => 'user'
    }),
    updateProfile: builder.mutation<any, Partial<GetUser> & { reason: string }>(
      {
        query: body => ({
          url: 'updateProfile',
          method: 'POST',
          body
        }),
        transformErrorResponse
      }
    ),
    unlockUser: builder.mutation<BasicResponse, UserActionMutationArgs>({
      query: ({ email, reason }) => ({
        url: 'unlock',
        method: 'POST',
        body: { email, reason }
      }),
      transformErrorResponse
    }),
    deleteProfile: builder.mutation<BasicResponse, UserActionMutationArgs>({
      query: ({ email, reason }) => ({
        url: 'deleteProfile',
        method: 'POST',
        body: { email, reason }
      })
    }),
    resetUserPassword: builder.mutation<
      BasicResponse,
      ResetUserPasswordMutationArgs
    >({
      query: ({ userId, reason }) => ({
        url: `user/${userId}/password`,
        method: 'POST',
        body: { reason }
      })
    }),
    /* Participants */
    getParticipantProfile: builder.query<
      GetParticipantProfileResponse,
      { id: string; studyId: string }
    >({
      query: ({ id, studyId }) => `/participants/details/${studyId}/${id}`,
      transformResponse: (response: GetParticipantProfileResponse) =>
        transformNullValuesToEmptyString(response)
    }),
    updateParticipantProfile: builder.mutation<
      BasicResponse,
      {
        id: string;
        studyId: string;
        data: Partial<GetParticipantProfile> & { reason: string };
      }
    >({
      query: ({ studyId, id, data }) => ({
        url: `participants/${studyId}/${id}`,
        method: 'POST',
        body: data
      }),
      transformErrorResponse
    }),
    updatePassword: builder.mutation<BasicResponse, UpdatePassword>({
      query: body => ({
        url: 'update/password',
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    getParticipants: builder.query<
      GetParticipantsResponse,
      ParticipantQueryParams
    >({
      query: ({
        trialId,
        id,
        sites,
        statuses,
        tags,
        flags,
        search,
        sortBy,
        sortOrder,
        skip = 0,
        limit = 10
      }) => ({
        url: `participants/${trialId}`,
        method: 'GET',
        params: {
          id,
          sites: handleQueryParam(sites),
          participantStatuses: handleQueryParam(statuses),
          tagIds: handleQueryParam(tags),
          flags: handleQueryParam(flags),
          search: handleQueryParam(search),
          sortBy: sortBy === 'status' ? 'participantStatus' : sortBy,
          sortOrder,
          skip,
          limit
        }
      })
    }),
    createParticipant: builder.mutation<
      BasicResponse & { userId: string },
      CreateParticipantMutationArgs
    >({
      query: ({ trialId, body, reason }) => ({
        url: `participants/${trialId}`,
        method: 'POST',
        body: {
          ...body,
          reason
        }
      }),
      transformErrorResponse
    }),
    getParticipantDevices: builder.query<
      GetParticipantDevicesResponse,
      GetParticipantDevicesQueryParams
    >({
      query: ({ id, studyId }) => `/participants/devices/${studyId}/${id}`
    }),
    /* Tags */
    createTag: builder.mutation<
      BasicResponse & { tag: { id: number; label: string } },
      CreateTagMutationArgs
    >({
      query: body => ({
        url: 'tag',
        method: 'POST',
        body
      })
    }),
    unlinkTag: builder.mutation<BasicResponse, AssignTagMutationArgs>({
      query: ({ userId, tagId }) => ({
        url: `user/${userId}/tag/${tagId}`,
        method: 'DELETE'
      })
    }),
    assignTag: builder.mutation<BasicResponse, AssignTagMutationArgs>({
      query: ({ userId, tagId }) => ({
        url: `user/${userId}/tag/${tagId}`,
        method: 'POST'
      })
    }),
    searchTag: builder.query<GetTagsResponse, SearchTagsQueryParams>({
      query: ({ search }) => ({
        url: 'tag/search',
        method: 'GET',
        params: { search }
      })
    }),
    getTags: builder.query<GetTagsResponse, GetTagsQueryParams>({
      query: ({ search, skip, limit }) => ({
        url: 'tag/search',
        method: 'GET',
        params: { search, skip, limit }
      })
    }),
    /* Flags */
    getFlags: builder.query<GetFlagsResponse, GetFlagsQueryParams>({
      query: () => ({
        url: 'availableFlags',
        method: 'GET'
      })
    }),
    deleteParticipant: builder.mutation<BasicResponse, DeleteParticipant>({
      query: ({ id, reason }) => ({
        url: `participants/${id}?reason=${reason.replaceAll(' ', '+')}`,
        method: 'DELETE'
      })
    }),
    deleteProfessional: builder.mutation<BasicResponse, deleteProfessional>({
      query: ({ userId, reason }) => ({
        url: `deleteProfile`,
        method: 'DELETE',
        body: {
          userId: userId,
          reason: reason
        }
      })
    }),
    getParticipantSurveys: builder.query<
      GetParticipantSurveysResponse,
      GetParticipantSurveysQueryParams
    >({
      query: ({ participantId, sortOrder, sortBy, skip, limit }) => ({
        url: `participants/${participantId}/surveys`,
        params: {
          sortBy: (() => {
            switch (sortBy) {
              case 'ts':
                return 'ts_completed';
              case 'flags':
                return 'flag_count';
              default:
                return sortBy;
            }
          })(),
          sortOrder,
          skip,
          limit
        }
      })
    }),
    /* Activities */
    getActivities: builder.query<
      GetActivitiesResponse,
      GetActivitiesQueryParams
    >({
      query: ({
        participantId,
        skip,
        limit,
        dataSource,
        startDate,
        endDate,
        activity,
        sortOrder,
        sortBy
      }) => ({
        url: 'activities',
        method: 'GET',
        params: {
          participantId,
          skip,
          limit,
          startDate,
          endDate,
          sortOrder,
          sortBy,
          source: handleQueryParam(dataSource),
          type: handleQueryParam(activity)
        }
      })
    }),
    getActivityOptions: builder.query<
      GetActivityOptionsResponse,
      GetActivityOptionsQueryParams
    >({
      query: ({ participantId }) => ({
        url: `activities/options/${participantId}`,
        method: 'GET'
      })
    }),
    /* Devices */
    getParticipantDevicesList: builder.query<
      GetDevicesListResponse,
      GetParticipantDevicesListParms
    >({
      query: ({ participantId }) => ({
        url: `/participants/${participantId}/devices`,
        method: 'GET'
      })
    }),
    getDeviceHistory: builder.query<
      GetDeviceHistoryResponse,
      GetDeviceHistoryParams
    >({
      query: ({ deviceId }) => ({
        url: `/device/${deviceId}/status/history`,
        method: 'GET'
      })
    })
  })
});

export default api;
export const {
  useGetAvailableSitesQuery,
  useGetAssignedStudiesQuery,
  useGetStudiesQuery,
  useCreateStudyMutation,
  useUpdateStudyMutation,
  useDeleteStudyMutation,
  useCreatePasswordSubmissionMutation,
  useForgotPasswordSubmissionMutation,
  useResetPasswordSubmissionMutation,
  useGetProfessionalsQuery,
  useGetParticipantProfileQuery,
  useLazyGetParticipantProfileQuery,
  useGetProfessionalByIdQuery,
  useLazyGetProfessionalByIdQuery,
  useResendProfessionalInviteMutation,
  useGetAuditsQuery,
  useGetAuditsActionsQuery,
  useGetAuditsSitesQuery,
  useGetAuditsSubjectsQuery,
  useGetAuditsUsersQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useGetRolesQuery,
  useCreateProfessionalMutation,
  useUpdateProfessionalMutation,
  useUpdateParticipantProfileMutation,
  useGetParticipantsQuery,
  useLazyGetParticipantsQuery,
  useCreateParticipantMutation,
  useUnlockUserMutation,
  useDeleteProfileMutation,
  useResetUserPasswordMutation,
  useGetCGMSummaryQuery,
  useGetBGMSummaryQuery,
  useCreateTagMutation,
  useAssignTagMutation,
  useUnlinkTagMutation,
  useSearchTagQuery,
  useLazySearchTagQuery,
  useGetFormsQuery,
  useGetSurveyAnswersQuery,
  useGetParticipantFormsQuery,
  useCreateParticipantFormMutation,
  useGetParticipantDevicesQuery,
  useGetTagsQuery,
  useGetFlagsQuery,
  useDeleteParticipantMutation,
  useDeleteProfessionalMutation,
  useGetParticipantSurveysQuery,
  useGetActivitiesQuery,
  useGetActivityOptionsQuery,
  useGetParticipantDevicesListQuery,
  useLazyGetDeviceHistoryQuery
} = api;
