import React from 'react';
import { useTheme } from 'styled-components';
import Dialog from '../molecules/Dialog';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
  DialogHeader
} from '../atoms/dialog';
import { Body1Div, H3 } from '../atoms/typography';
import { CloseIcon } from '../atoms/images';
import { PrimaryButton, SecondaryButton } from '../atoms/buttons';

type TwoFactorErrorModalProps = {
  isOpen: boolean;
  isResendDisabled: boolean;
  handleClose: () => void;
  handleResend: () => void;
};

function TwoFactorErrorModal({
  isOpen,
  handleClose,
  isResendDisabled,
  handleResend
}: Readonly<TwoFactorErrorModalProps>) {
  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {}
  } = useTheme();

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} width={480}>
      <DialogHeader>
        <H3>
          {config?.verificationCodeErrorHeaderText ?? 'Invalid or expired code'}
        </H3>
        <CloseIcon onClick={handleClose} />
      </DialogHeader>
      <DialogBody $minHeight={200} $center>
        <Body1Div>
          {config?.verificationCodeErrorMessage ??
            'Invalid or expired verification code. Please try again or resend a new one.'}
        </Body1Div>
      </DialogBody>
      <DialogFooter $mode="flex-end">
        <DialogFooterActions>
          <SecondaryButton onClick={handleClose} data-testid="cancel-modal">
            {config?.verificationCodeErrorCloseButtonLabel ?? 'Close'}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            onClick={handleResend}
            disabled={isResendDisabled}
            data-testid="resend-code-modal-button"
          >
            {config?.verificationCodeErrorResendButtonLabel ??
              'Resend verification code'}
          </PrimaryButton>
        </DialogFooterActions>
      </DialogFooter>
    </Dialog>
  );
}

export default TwoFactorErrorModal;
