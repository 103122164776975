import React from 'react';
import { ColumnWithGap, InfoAlert } from '../atoms/containers';
import { Body1Div, H4 } from '../atoms/typography';
import { DismissAlertBtn } from '../atoms/buttons';

type LoggedOutIdleAlertProps = {
  message?: string;
  title?: string;
  handleDismiss?: () => void;
};

function LoggedOutIdleAlert({
  title,
  message,
  handleDismiss
}: Readonly<LoggedOutIdleAlertProps>) {
  return (
    <InfoAlert>
      <ColumnWithGap $gap="4px" $align="start">
        <H4>{title ?? 'Logged out.'}</H4>
        <Body1Div>
          {message ??
            'Your account has been logged out due to inactivity. Please login again to access the portal.'}
        </Body1Div>
      </ColumnWithGap>
      {handleDismiss ? (
        <DismissAlertBtn
          type="button"
          className="close"
          onClick={handleDismiss}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </DismissAlertBtn>
      ) : null}
    </InfoAlert>
  );
}

export default LoggedOutIdleAlert;
