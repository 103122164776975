import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { redirectUser } from '@src/utils/OneVueAPI';
import * as authActions from '@src/actions/auth';
import * as recordsActions from '@src/actions/records';
import { useActions } from '@src/hooks/useActions';
import { useAuth } from '@src/selectors/auth';
import { UnauthenticatedFormContainer } from '@ts/components/templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { FormInput } from '@ts/components/molecules/AuthFormInput';
import { H2 } from '@ts/components/atoms/typography';
import {
  FormContainer,
  ParagraphCentered
} from '@ts/components/atoms/containers';
import { TextLinkButton, PrimaryButton } from '@ts/components/atoms/buttons';
import { FormLogo } from '@ts/components/atoms/images';
import { VerticalGap } from '@ts/components/atoms/spacing';
import replaceTemplates from '@ts/utils/replaceTemplates';
import replaceTemplatesInString from '@src/utils/strings/replaceTemplatesInString';
import TwoFactorErrorModal from '@ts/components/organisms/TwoFactorErrorModal';

function TwoFactorVerification({ history }: Readonly<RouteChildrenProps>) {
  const actions = useActions(authActions);
  const recordActions = useActions(recordsActions);

  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {},
    mainLogo
  } = useTheme();
  const location = useLocation();
  const { authToken, isLoading, profileSession, error } = useAuth();

  const email = useMemo(() => location.state?.email, [location.state?.email]);
  const password = useMemo(() => location.state?.password, [
    location.state?.password
  ]);

  const [code, setCode] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [resendIsOnHold, setResendIsOnHold] = useState(false);
  const [resendOnHoldTime, setResendOnHoldTime] = useState(30);

  const handleCodeChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setCode(value);
    },
    [setCode]
  );

  const toggleCheckboxChange = useCallback(() => {
    setRememberMe(checked => !checked);
  }, [setRememberMe]);

  const handleSubmit = useCallback(() => {
    actions.login(email, password, code, rememberMe);
  }, [actions, email, password, code, rememberMe]);

  const handleResendCode = useCallback(() => {
    if (resendIsOnHold) return;
    actions.login(email, password);
    setResendIsOnHold(true);
  }, [actions, email, password, resendIsOnHold]);

  const handleModalClose = useCallback(() => {
    actions.clearError();
  }, [actions]);

  const handleModalResend = useCallback(() => {
    handleResendCode();
    actions.clearError();
  }, [actions, handleResendCode]);

  useEffect(() => {
    if (resendIsOnHold) {
      const intervalId = setInterval(() => {
        if (resendOnHoldTime <= 0) {
          setResendIsOnHold(false);
          setResendOnHoldTime(30);
          clearInterval(intervalId);
        } else {
          setResendOnHoldTime(x => x - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [resendIsOnHold, resendOnHoldTime]);

  useEffect(() => {
    if (authToken) {
      recordActions.initRecords();
      redirectUser({ history, profileSession, authToken }, true);
    }
  }, [authToken, history, profileSession, recordActions]);

  return (
    <UnauthenticatedFormContainer>
      <FormLogo alt={mainLogo.alt} src={mainLogo.src} />
      <FormContainer>
        <H2 $align="center">
          {config?.verificationCodePageTitle ?? 'Verify your login'}
        </H2>
        <VerticalGap $gap={24} />
        <ParagraphCentered>
          {replaceTemplates(
            config?.verificationCodePageSubtitle ??
              'To continue, enter the verification code sent to {email}',
            { email: <b>{email}</b> }
          )}
        </ParagraphCentered>
        <VerticalGap $gap={24} />
        <FormInput
          labelClassName="landing-label"
          label={config?.verificationCodeField?.label ?? 'Verification Code'}
          inputType="text"
          inputID="code"
          data-testid="verify-code-input"
          value={code}
          required={true}
          onChange={handleCodeChange}
          invalidFeedback={
            config?.verificationCodeField?.errorMessage ??
            'Please enter your study ID'
          }
        />
        <FormInput
          wrapperClassName="d-flex flex-row flex-wrap align-content-start"
          inputWrapperClassName="p-1 align-content-start"
          labelClassName="m-0 px-2 flex-1"
          type="checkbox"
          label={config?.verificationCodeField?.label ?? 'Remember this device'}
          description={
            config?.verificationCodeField?.description ??
            'We will not ask for a verification code on this device until you log out. Select only if this is not a shared device.'
          }
          inputType="checkbox"
          inputID="rememberMe"
          data-testid="remember-me-input"
          checked={rememberMe ?? false}
          onChange={toggleCheckboxChange}
        />
      </FormContainer>
      <VerticalGap $gap={40} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <PrimaryButton
          data-testid="submit-btn"
          onClick={handleSubmit}
          type="button"
          disabled={!code || isLoading}
          $minWidth="160px"
        >
          {config?.verificationCodePageSubmit ?? 'Submit'}
        </PrimaryButton>
      </div>
      <TextLinkButton disabled={resendIsOnHold} onClick={handleResendCode}>
        {resendIsOnHold
          ? replaceTemplatesInString(
              config?.resendVerificationCodeText ??
                'Please wait {time}s before requesting another code',
              {
                time: resendOnHoldTime
              }
            )
          : config?.resendVerificationCodeText ?? 'Resend verification code'}
      </TextLinkButton>
      <TwoFactorErrorModal
        isOpen={!!error?.message || !!error}
        isResendDisabled={resendIsOnHold}
        handleClose={handleModalClose}
        handleResend={handleModalResend}
      />
    </UnauthenticatedFormContainer>
  );
}

export default TwoFactorVerification;
