import React from 'react';
import { ErrorAlert } from '../atoms/containers';
import { DismissAlertBtn } from '../atoms/buttons';

type LoginErrorAlertProps = {
  message?: string;
  handleDismiss: () => void;
};

function LoginErrorAlert({
  handleDismiss,
  message
}: Readonly<LoginErrorAlertProps>) {
  return (
    <ErrorAlert role="alert" data-cy="error-alert" data-testid="error-alert">
      {message ??
        'The email or password you entered is incorrect, or your account has been locked due to multiple unsuccessful login attempts. Contact support for assistance.'}
      <DismissAlertBtn
        type="button"
        className="close"
        onClick={handleDismiss}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </DismissAlertBtn>
    </ErrorAlert>
  );
}

export default LoginErrorAlert;
