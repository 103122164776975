import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';
import useLoginData from '@src/Components/Login/useLoginData';
import { some } from 'lodash';

import { RouteChildrenProps, Link } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import {
  FormContainer,
  FormAlertContainer,
  RowCentered,
  ParagraphCentered
} from '../../atoms/containers';
import { GhostBtn, PrimaryButton } from '../../atoms/buttons';
import { FormLogo } from '../../atoms/images';
import { VerticalGap } from '../../atoms/spacing';
import { Body1Div, H2 } from '../../atoms/typography';
import { Modal } from '@ts/components/molecules/Modal';
import LoginErrorAlert from '@ts/components/molecules/LoginErrorAlert';
import LoggedOutIdleAlert from '@ts/components/molecules/LoggedOutIdleAlert';

export const LandingLoginPage = ({
  history
}: RouteChildrenProps<{ landing?: string }>) => {
  const {
    formData: { email, password, studyId },
    errors,
    success,
    loginError,
    isSignInClicked,
    loggedOutFromIdle,
    handleChange,
    handleDidClickSignIn,
    handleKeyUp,
    handleDismissError,
    handleDismissIdle
  } = useLoginData(history);

  const [isValidStudyId, setIsValidStudyId] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [stage, setStage] = useState(0);

  const handleStudyIdContinue = useCallback(() => {
    if (isValidStudyId) {
      setStage(1);
    } else {
      setStage(0);
    }
  }, [isValidStudyId]);

  useEffect(() => {
    setIsValidStudyId(studyId.length > 8);
  }, [studyId, isValidStudyId]);

  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {},
    mainLogo
  } = useTheme();

  const firstPhase = useMemo(() => stage == 0, [stage]);

  const secondPhase = useMemo(() => {
    return isValidStudyId && stage == 1;
  }, [isValidStudyId, stage]);

  const loginTitle = useMemo(() => {
    if (firstPhase) {
      setSubtitle(
        config?.loginSubtitle ?? 'To log in, first enter the Study ID'
      );
      return config?.landingFirstTitle ?? 'Enter Study ID';
    } else if (secondPhase) {
      setSubtitle(
        config?.landingSecondTitle ??
          `Enter the credentials associated with Study `
      );
      return config?.landingSecondSubtitle ?? 'Enter Credentials';
    } else {
      return config?.loginTitle ?? 'Log in';
    }
  }, [
    secondPhase,
    firstPhase,
    config?.loginTitle,
    config?.loginSubtitle,
    config?.landingFirstTitle,
    config?.landingSecondTitle,
    config?.landingSecondSubtitle
  ]);

  const backButtonHandle = useCallback(() => {
    setStage(0);
  }, []);

  const alert = useMemo(() => {
    if (window.location.search.includes('error')) {
      return (
        <LoginErrorAlert
          message={config?.loginFailureMessage}
          handleDismiss={handleDismissError}
        />
      );
    }

    if (loggedOutFromIdle) {
      return (
        <LoggedOutIdleAlert
          title={config?.loggedOutIdleTitle}
          message={config?.loggedOutIdleMessage}
          handleDismiss={handleDismissIdle}
        />
      );
    }

    return null;
  }, [
    loggedOutFromIdle,
    config?.loggedOutIdleMessage,
    config?.loggedOutIdleTitle,
    config?.loginFailureMessage,
    handleDismissError,
    handleDismissIdle
  ]);

  const validated = useMemo(() => {
    if (!email || !password) return false;
    if (some(errors, Boolean)) return false;
    return true;
  }, [email, password, errors]);

  return (
    <UnauthenticatedFormContainer>
      <Modal
        id="error-modal"
        title={'Error'}
        visible={loginError}
        submitText={'Close'}
        maxWidth={411}
        onClose={handleDismissError}
        alignStart
        bodyMinHeight={84}
      >
        <Body1Div>
          {config?.landingLoginError ??
            'There was an error logging you in. Please try again or reset your password.'}
        </Body1Div>
      </Modal>
      <FormAlertContainer>{alert}</FormAlertContainer>
      <FormLogo alt={mainLogo.alt} src="/trial_sync_logo.png" />
      <FormContainer>
        <H2 $align="center">{loginTitle}</H2>
        <VerticalGap $gap={24} />
        <ParagraphCentered>
          <span>{subtitle}</span>
          {secondPhase && <span>{studyId}</span>}
        </ParagraphCentered>
        {firstPhase && <VerticalGap $gap={24} />}
        {firstPhase && (
          <FormInput
            labelClassName="landing-label"
            label={config?.loginStudyIdField?.label ?? 'Study ID'}
            inputType="text"
            inputID="studyId"
            data-testid="study-id-input"
            value={studyId}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginStudyIdField?.errorMessage ??
              'Please enter your study ID'
            }
            errors={errors}
            success={success}
          />
        )}
        {firstPhase && <VerticalGap $gap={24} />}
        {firstPhase && (
          <RowCentered>
            <PrimaryButton
              data-testid="study-id-submit-btn"
              onClick={handleStudyIdContinue}
              type="button"
              disabled={!isValidStudyId}
              $minWidth="160px"
            >
              {config?.continueLabel ?? 'Continue'}
            </PrimaryButton>
          </RowCentered>
        )}
        <VerticalGap $gap={24} />
        {secondPhase && (
          <FormInput
            labelClassName="landing-label"
            label={config?.loginEmailField?.label ?? 'Email*'}
            inputType="text"
            inputID="email"
            data-testid="email-input"
            placeholder=""
            value={email}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginEmailField?.errorMessage ?? 'Please enter your email'
            }
            errors={errors}
            success={success}
          />
        )}
        {secondPhase && <VerticalGap $gap={24} />}
        {secondPhase && (
          <FormInput
            labelClassName="landing-label"
            label={config?.loginPasswordField?.label ?? 'Password*'}
            showLabel={config?.loginPasswordField?.showLabel}
            hideLabel={config?.loginPasswordField?.hideLabel}
            inputType="password"
            inputID="password"
            data-testid="password-input"
            placeholder=""
            value={password}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginPasswordField?.errorMessage ??
              'Please enter a password that is at least 8 characters long with at least one letter and one number'
            }
            errors={errors}
            success={success}
            onKeyUp={handleKeyUp}
          />
        )}
      </FormContainer>
      {secondPhase && <VerticalGap $gap={40} />}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {secondPhase && (
          <PrimaryButton
            data-testid="loginform-back-btn"
            onClick={backButtonHandle}
            type="button"
            $minWidth="160px"
          >
            {config?.backLabel ?? 'Back'}
          </PrimaryButton>
        )}
        <div style={{ padding: 10 }}></div>
        {secondPhase && (
          <PrimaryButton
            data-testid="loginform-submit-btn"
            onClick={handleDidClickSignIn}
            type="button"
            disabled={!validated}
            $minWidth="160px"
          >
            {isSignInClicked ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              config?.loginTitle ?? 'Log in'
            )}
          </PrimaryButton>
        )}
      </div>
      {secondPhase && (
        <Link
          to={{
            pathname: '/forgot-password',
            state: {
              email: email
            }
          }}
        >
          <GhostBtn>
            {config?.forgotPasswordText ?? 'Forgot Password?'}
          </GhostBtn>
        </Link>
      )}
    </UnauthenticatedFormContainer>
  );
};
