import React, { useCallback, useEffect, useRef } from 'react';
import { PrimaryButton, SecondaryButton } from '../atoms/buttons';
import Dialog from './Dialog';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
  DialogHeader
} from '../atoms/dialog';
import { H3 } from '../atoms/typography';
import { CloseIcon } from '../atoms/images';

type ModalProps = {
  visible?: boolean;
  id?: string;
  bodyGap?: string;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  overlay?: React.ReactNode;
  onClickSubmit?: () => void;
  submitText?: string;
  showCancelButton?: boolean;
  disableSubmit?: boolean;
  disableCancel?: boolean;
  onClickCancel?: () => void;
  cancelText?: string;
  closeOnBackdropClick?: boolean;
  maxWidth?: number;
  bodyMinHeight?: number;
  alignStart?: boolean;
  loadingSubmit?: boolean;
  overflow?: string;
  getBodyRef?: (bodyRef: React.RefObject<HTMLDivElement>) => void;
};

export function Modal({
  visible,
  id,
  onClose,
  children,
  overlay,
  title,
  onClickSubmit,
  submitText,
  showCancelButton,
  onClickCancel,
  cancelText,
  disableSubmit,
  disableCancel,
  closeOnBackdropClick,
  maxWidth,
  bodyMinHeight,
  alignStart,
  bodyGap,
  loadingSubmit,
  overflow,
  getBodyRef
}: ModalProps) {
  const modalEl = useRef<HTMLDivElement>(null);
  const bodyEl = useRef<HTMLDivElement>(null);
  const handleMouseUp = useCallback(
    (event: Event) => {
      const path = event.composedPath();
      if (!path.find(element => element === modalEl.current)) {
        if (visible) onClose();
      }
    },
    [visible, onClose]
  );

  useEffect(() => {
    if (closeOnBackdropClick) {
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [handleMouseUp, closeOnBackdropClick]);

  useEffect(() => {
    getBodyRef?.(bodyEl);
  }, [bodyEl, getBodyRef]);

  return (
    <Dialog isOpen={visible} onClose={onClose} width={maxWidth ?? 792}>
      <DialogHeader>
        <H3>{title}</H3>
        <CloseIcon
          onClick={onClose}
          data-testid={id ? `${id}-close-modal-btn` : 'close-modal-btn'}
        />
      </DialogHeader>
      <DialogBody
        ref={bodyEl}
        $gap={bodyGap}
        $minHeight={bodyMinHeight ?? 200}
        $center={!alignStart}
        $overflow={overflow}
      >
        {children}
      </DialogBody>
      <DialogFooter $mode="flex-end">
        <DialogFooterActions>
          {showCancelButton ? (
            <SecondaryButton
              onClick={onClickCancel || onClose}
              disabled={disableCancel || loadingSubmit}
              data-testid={id ? `${id}-modal-cancel-btn` : 'modal-cancel-btn'}
            >
              {cancelText || 'Cancel'}
            </SecondaryButton>
          ) : null}
          <PrimaryButton
            type="button"
            onClick={onClickSubmit || onClose}
            disabled={disableSubmit || loadingSubmit}
            data-testid={id ? `${id}-modal-submit-btn` : 'modal-submit-btn'}
          >
            {loadingSubmit ? (
              <i
                className="fas fa-circle-notch fa-spin"
                data-testid="modal-submit-btn-loading"
              />
            ) : (
              submitText || 'OK'
            )}
          </PrimaryButton>
        </DialogFooterActions>
      </DialogFooter>
      {overlay}
    </Dialog>
  );
}
