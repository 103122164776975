import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';

import { RouteChildrenProps } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { FormContainer } from '../atoms/containers';
import { H2, H4 } from '../atoms/typography';
import { PrimaryButton } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import { useForgotPasswordSubmissionMutation } from '@src/ts/services/api';
import { validateEmail } from '@ts/utils/validations';
import { SessionManagementAlert } from '../organisms/SessionManagementAlert';
import { SessionManagementFeedback } from '../organisms/SessionManagementFeedback';

export const ForgotPasswordPage = ({ history }: RouteChildrenProps) => {
  const [email, setEmail] = useState('');

  const [
    submitForgotPassword,
    { isError: error, isLoading: loading, isSuccess: success, reset: clear }
  ] = useForgotPasswordSubmissionMutation();

  const { screenAndPagesContentConfiguration, mainLogo } = useTheme();

  const config =
    screenAndPagesContentConfiguration?.passwordMgtContentConfiguration;

  const handleDidClickSendCode = useCallback(() => {
    submitForgotPassword({ email });
  }, [email, submitForgotPassword]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const validated = useMemo(() => {
    return validateEmail(email);
  }, [email]);

  const handleGoToPortal = useCallback(() => {
    history?.replace('/login');
  }, [history]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  return (
    <UnauthenticatedFormContainer>
      <SessionManagementAlert
        message={
          config?.forgotPasswordFailureMessage ||
          'Something went wrong. Please try again later.'
        }
        dismiss={clear}
        display={error && !success}
      />
      {!success && <FormLogo alt={mainLogo.alt} src={mainLogo.src} />}
      <FormContainer>
        {success ? (
          <SessionManagementFeedback
            image={config?.forgotPasswordImage}
            fallbackIconSrc="/portal/mail-sent.svg"
            message={
              config?.forgotPasswordSuccessMessage ||
              `We sent a link to ${email}.\nDon’t see the email? Check your spam folder!`
            }
          />
        ) : (
          <>
            <H2 $align="center">
              {config?.forgotPasswordTitle || 'Forgot password'}
            </H2>
            <H4 $fontWeight={400} $align="center" $margin="8px 0 16px 0">
              {config?.forgotPasswordText ||
                `Enter your email, and we will send you a link to reset your password.`}
            </H4>
            <VerticalGap $gap={24} />
            <FormInput
              label={config?.forgotPasswordEmailField?.label || 'Email'}
              inputType="email-address"
              inputID="email"
              data-testid="email-input"
              placeholder={
                config?.forgotPasswordEmailField?.placeholder ||
                'you@example.com'
              }
              errors={email && !validated ? { email: true } : {}}
              value={email}
              required={true}
              onChange={handleChange}
              invalidFeedback={
                config?.forgotPasswordEmailField?.errorMessage ||
                'Please enter your email'
              }
              // errors={errors}
              success={success}
            />
          </>
        )}
      </FormContainer>
      <VerticalGap $gap={40} />
      {success ? (
        <PrimaryButton
          $minWidth="160px"
          data-testid="forgot-password-email-sent-proceed-btn"
          onClick={handleGoToPortal}
          type="button"
        >
          {config?.forgotPasswordSuccessButtonLabel || 'Go to Portal'}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          $minWidth="160px"
          data-testid="forgot-password-submit-btn"
          onClick={handleDidClickSendCode}
          type="button"
          disabled={!validated}
        >
          {loading ? (
            <i className="fas fa-circle-notch fa-spin" />
          ) : (
            config?.forgotPasswordButtonLabel || 'Reset Password'
          )}
        </PrimaryButton>
      )}
    </UnauthenticatedFormContainer>
  );
};
