import { omit } from 'lodash';

export default class StateLoader {
  loadState() {
    try {
      let serializedState = localStorage.getItem('iPEP:state');

      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      let serializedState = JSON.stringify(omit(state, 'prnOptions'));

      localStorage.setItem('iPEP:state', serializedState);
    } catch (err) {
      /*eslint no-console: ["error", { allow: [ "error"] }] */
      console.error(err);
    }
  }

  initializeState() {
    return {
      auth: {
        authToken: '',
        profileSession: {},
        loadingError: '',
        twoFactor: false,
        isLoading: true
      },
      user: {
        consents: {
          consents: [],
          activeUserConsent: null,
          isLoadingConsent: false
        },
        shrStatus: 0,
        inbox: {
          inbox: [],
          isLoading: false,
          isLoadingError: ''
        },
        loadingError: '',
        isLoading: true,
        doctor: {
          data: {},
          loadingError: '',
          isLoading: true
        }
      },
      providers: {
        providers: [],
        providersByPatient: {},
        loadingError: '',
        isLoading: false
      },
      records: {
        records: [],
        recordCount: 0,
        selectedRequest: '',
        recordCards: {
          recordCount: 0,
          isLoading: true
        },
        recordDetail: {
          recordDetail: {},
          isLoading: true
        },
        filters: {
          facilities: [],
          doctors: []
        },
        loadingError: '',
        isLoading: true
      },

      recordsInvitations: {
        recordsInvitations: {},
        loadingError: '',
        isLoading: true
      },
      prnOptions: {
        items: [],
        didLoad: false,
        isLoading: false,
        error: null
      },
      cmsLocales: {
        locales: [],
        userLocale: null,
        didLoad: false,
        isLoading: false,
        error: null
      }
    };
  }
}
