import React from 'react';
import {
  FormPageContainer,
  PageContentContainer
} from '@ts/components/atoms/containers';
import LocaleSelector from '@ts/components/organisms/LocaleSelector';

interface UnauthenticatedFormContainerProps {
  children: React.ReactNode;
}

export const UnauthenticatedFormContainer: React.FC<UnauthenticatedFormContainerProps> = ({
  children
}) => {
  return (
    <FormPageContainer>
      <PageContentContainer>
        <LocaleSelector />
        {children}
      </PageContentContainer>
    </FormPageContainer>
  );
};
