import React, { Fragment, ReactNode } from 'react';
import { isString, reduce } from 'lodash';

function splitAndInsert(
  template: string,
  regex: RegExp,
  value: ReactNode,
  key: string
) {
  const parts = template.split(regex);
  return parts.flatMap((s: string, i: number, arr: string[]) =>
    arr.length - 1 !== i
      ? [
          s,
          isString(value) ? (
            value
          ) : (
            <Fragment key={`${key}-${s}-${i}`}>{value}</Fragment>
          )
        ]
      : s
  );
}

export default function replaceTemplates(
  stringWithTemplate: string,
  replacements: { [key: string]: ReactNode } = {}
): ReactNode[] {
  if (!stringWithTemplate) return [];

  return reduce(
    replacements,
    (prev: ReactNode[], value: ReactNode, key: string) => {
      const templateRegex = new RegExp(String.raw`\{${key}\}`, 'g');
      if (!prev.length) {
        const parts = splitAndInsert(
          stringWithTemplate,
          templateRegex,
          value,
          key
        );
        return parts;
      } else {
        return prev.flatMap((s: ReactNode) =>
          isString(s) ? splitAndInsert(s, templateRegex, value, key) : s
        );
      }
    },
    []
  );
}
