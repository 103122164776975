import * as types from '../constants/actionTypes';
import StateLoader from './StateLoader';
import {
  removeCookie,
  removeUserProgram,
  clearAccessToken,
  removeUserPRNOption
} from '../utils/OneVueAPI';
import { createBrowserHistory as createHistory } from 'history';
let history = createHistory();
export default function authReducer(
  state = new StateLoader().loadState().auth,
  action
) {
  switch (action.type) {
    case types.LOGIN_START:
      return {
        ...state,
        error: null,
        authToken: '',
        twoFactor: false,
        isLoading: true
      };
    case types.LOGIN_SUCCESS: {
      const newState = { ...state };
      if (action.response.twoFactor) {
        newState.twoFactor = true;
      } else {
        const profileString = action.response.user.id ? action.response : null;
        const authToken = action.response.sessionKey;
        newState.authToken = authToken;
        newState.profileSession = profileString || state.profileSession;
      }
      newState.isLoading = false;
      return newState;
    }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        authToken: '',
        twoFactor: false,
        isLoading: false,
        error: action.error
      };
    case types.CLEAR_AUTH_ERROR: {
      return {
        ...state,
        error: null
      };
    }
    case types.FETCH_USER_START: {
      return {
        ...state,
        isProfileLoading: true
      };
    }
    case types.FETCH_USER_FAILURE: {
      return {
        ...state,
        isProfileLoading: false
      };
    }
    case types.SET_PROFILE: {
      return {
        ...state,
        profileSession: action.profile,
        isProfileLoading: false
      };
    }
    case types.LOGOUT_SUCCESS:
    case types.LOGOUT_FAILURE: {
      history.push('/login');
      removeUserProgram();
      removeUserPRNOption();
      clearAccessToken();
      localStorage.removeItem('iPEP:state');
      removeCookie();
      return {
        ...state,
        loggedOutFromIdle: action.meta?.fromIdle,
        authToken: '',
        profileSession: {},
        isLoading: false
      };
    }
    case types.CLEAR_IDLE_MESSAGE:
      return { ...state, loggedOutFromIdle: false };
    default:
      return state;
  }
}
