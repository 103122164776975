import * as types from '../constants/actionTypes';
import request from '../utils/request';
import * as api from '../utils/OneVueAPI';
import { getConsents } from './user';

const loginStart = () => {
  return {
    type: types.LOGIN_START,
    meta: {
      crossTab: true
    }
  };
};

const loginSuccess = response => {
  return {
    type: types.LOGIN_SUCCESS,
    meta: {
      crossTab: true
    },
    response
  };
};

const loginFailure = error => {
  return {
    type: types.LOGIN_FAILURE,
    meta: {
      crossTab: true
    },
    error
  };
};

const logoutStart = () => {
  return {
    type: types.LOGOUT_START,
    meta: {
      crossTab: true
    }
  };
};

const logoutSuccess = fromIdle => {
  return {
    type: types.LOGOUT_SUCCESS,
    meta: {
      crossTab: true,
      fromIdle
    }
  };
};

const logoutFailure = (error, fromIdle) => {
  return {
    type: types.LOGOUT_FAILURE,
    meta: {
      crossTab: true,
      fromIdle
    },
    error
  };
};

export const clearError = () => dispatch =>
  dispatch({ type: types.CLEAR_AUTH_ERROR });

export const clearIdleMessage = () => dispatch =>
  dispatch({
    type: types.CLEAR_IDLE_MESSAGE
  });

export const login = (email, password, code, rememberMe) => {
  return async dispatch => {
    try {
      dispatch(loginStart());
      const body = { email, password };
      if (code) {
        body.otp = code;
        body.rememberMe = rememberMe;
      }
      const loginConfig = {
        url: 'login',
        method: 'POST',
        data: body
      };
      const twoFactorToken = api.get2faAccessToken();
      if (!code && twoFactorToken) {
        loginConfig.headers = {
          'Skip-2fa': twoFactorToken
        };
      }

      const response = await request(loginConfig);

      const json = response.data;

      if (json.success) {
        dispatch(loginSuccess(json));
        if (!json.twoFactor) {
          const profileString = json.user.id ? json : null;
          const authToken = json.sessionKey;
          api.storeUserProgram(profileString);
          api.storeUserPRNOption(profileString);
          api.setAccessToken(authToken, rememberMe);
          api.storeCookie(authToken);
        }
        if (json.sessionKey) {
          dispatch(getConsents(json.sessionKey));
        }
      } else if (json.twoFactor) {
        // and success is false
        dispatch(loginFailure(json.error));
      } else {
        dispatch(loginFailure('No session key provided'));
      }
    } catch (err) {
      dispatch(loginFailure(err));
    }
  };
};

export const logout = (accessToken, fromIdle) => {
  return async dispatch => {
    try {
      dispatch(logoutStart());

      await request({
        url: 'logout',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Session-Key': accessToken
        }
      });

      dispatch(logoutSuccess(fromIdle));
    } catch (err) {
      dispatch(logoutFailure(err, fromIdle));
    } finally {
      api.logout(!!accessToken);
    }
  };
};
