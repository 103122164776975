import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom-v5-compat';
import config from '../../config/config';
import { getAccessToken, redirectUser } from '../../utils/OneVueAPI';
import * as authActions from '../../actions/auth.js';
import * as recordsActions from '../../actions/records';
import { useActions } from '../../hooks/useActions.js';
import { useAuth } from '../../selectors/auth.js';
import { some } from 'lodash';
import { validateEmail } from '../../utils/validations.js';

const validations = {
  email: validateEmail,
  password: value => value.length > 0,
  studyId: value => value.length > 8
};

export default function useLoginData(history) {
  const actions = useActions(authActions);
  const recordActions = useActions(recordsActions);
  const {
    authToken,
    isLoading,
    profileSession,
    loggedOutFromIdle,
    twoFactor
  } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: config.EMAIL || '',
    password: config.PASSWORD || '',
    studyId: config.STUDY_ID || ''
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});
  const [isSignInClicked, setIsSignInClicked] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleChange = ({ target: { id, value } }) => {
    setFormData(current => ({ ...current, [id]: value }));
    if (!validations[id] || validations[id](value)) {
      setSuccess(current => ({ ...current, [id]: true }));
      setErrors(current => ({ ...current, [id]: false }));
    } else {
      setErrors(current => ({ ...current, [id]: true }));
    }
  };

  const handleDidClickSignIn = useCallback(
    event => {
      event.preventDefault();
      if (isSignInClicked) return;
      setIsSignInClicked(true);
      const cleanEmail = formData.email.trim().toLowerCase();
      if (!some(errors, Boolean)) {
        actions.login(cleanEmail, formData.password);
      } else {
        setIsSignInClicked(false);
      }
    },
    [formData.email, errors, formData.password, actions, isSignInClicked]
  );

  const handleKeyUp = useCallback(
    event => {
      if (event.key !== 'Enter') return;
      handleDidClickSignIn(event);
    },
    [handleDidClickSignIn]
  );

  const handleDismissError = () => setLoginError(false);
  const handleDismissIdle = () => actions.clearIdleMessage();

  useEffect(() => {
    document.body.style.backgroundColor = 'rgb(114,164,203)';
    ReactGA.pageview(window.location.pathname + window.location.search);
    const accessToken = getAccessToken();
    if (accessToken || authToken) {
      actions.logout(accessToken);
    }
    return () => {
      document.body.style.backgroundColor = 'rgb(255,255,255)';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authToken) {
      recordActions.initRecords();
      redirectUser({ history, profileSession, authToken }, true);
    }
  }, [authToken, history, profileSession, recordActions]);

  useEffect(() => {
    if (twoFactor && isSignInClicked) {
      setIsSignInClicked(false);
      navigate('/login/2fa', { state: { ...formData } });
    } else if (authToken === '' && !isLoading && isSignInClicked) {
      setLoginError(true);
      setIsSignInClicked(false);
    }
  }, [twoFactor, authToken, isLoading, isSignInClicked, navigate, formData]);

  return {
    formData,
    errors,
    success,
    actions,
    loginError,
    isSignInClicked,
    authToken,
    profileSession,
    loggedOutFromIdle,
    twoFactor,
    handleChange,
    handleDidClickSignIn,
    handleKeyUp,
    handleDismissError,
    handleDismissIdle
  };
}
